import React, { Component } from 'react';
import {Breadcrumb, Button, Image, Table} from 'react-bootstrap';
import 'moment/locale/id.js';
import ReactToPrint from "react-to-print";
import CurrencyFormat from 'react-currency-format';
const moment = require('moment');


class ComponentToPrint extends React.Component {

  constructor(props){
    super(props);

    this.state={
        student_id : '',
        is_show : false,
        is_showdata : false,
        image_64 : '',
    }
  }

  componentDidMount() {
    this.setState({student_id: localStorage.getItem('DATA_JALUR').split('-')[0]});
    
    fetch(global.variable.LINK_API + 'student/detail/'+ localStorage.getItem('DATA_JALUR').split('-')[0],
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'),
      },
      //body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        this.setState(resData.data[0]);
        console.log(this.state);
        this.setState({is_show:true})
		
		let id = '';
        if(this.state.upload_documents.length){
          this.state.upload_documents[0].document_id===1? id = this.state.upload_documents[0].id : id = '';
        }
		
		if(id!==''){
          fetch(global.variable.LINK_API + 'document/show/'+id,
          {
            method: 'get',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer '+ global.cookies.get('access_token'),
            },
            //body: JSON.stringify(this.state), 
          })
          .then(response =>  response.json())
          .then(resData => {
              console.log(JSON.stringify(resData));
            if(resData.message==="Success"){
              this.setState({image_64 : resData.data[0].link});
            }else{
              let msgerr = "";
              if(resData.errors){
                let count = Object.keys(resData.errors).length;
                for(let i=0; i<count; i++){
                  msgerr = msgerr + resData.errors[Object.keys(resData.errors)[i]] + "\n";
                }
              }
              global.swal(msgerr)
            }
            
          })
        }
      }
    })
    

    
  }


  render() {
    
    let counter = 0;
    let currency = Intl.NumberFormat('en-ID');
    return (

      this.state.is_show?
        <div id="divPrint" style={{width:810, border: '0px solid black'}}>
            <div style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center' ,textAlign: 'center',}}>
                <img src={require("../assets/images/kop_surat.png")} alt="kop" style={{width:800}}/>
            </div>
            <div style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center', fontSize:20, marginTop:20, textAlign: 'center', }}>
              <label style={{textDecorationLine: 'underline'}}>BUKTI PENDAFTARAN</label>
            </div>
			
			<div className="rowHorizontal">
              <div style={{width:200, height:100, border: '1px solid black', margin:20, marginLeft:50}}>
                <div style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center', fontSize:12, marginTop:10, textAlign: 'center', textDecorationLine: 'underline'}}>
                  <label>Jenis Formulir</label>
                </div>
                <hr style={{border:'none', height:1, backgroundColor:'black'}}/>
                <div style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center', fontSize:12, marginTop:0, textAlign: 'center', textDecorationLine: 'underline'}}>
                  <label>{this.state.registration_type.name}</label>
                </div>
              </div>


              <div style={{width:120, height:160, border: '1px solid black', margin:20, marginLeft: 400}}>
                  {this.state.image_64?
                  <Image alt="Foto" style={{width: '100%', height: '100%'}} src={this.state.image_64} thumbnail />
                  :''}
              </div>
            </div>

            <div style={{position:'relative'}}>
              <img src={require("../assets/images/lunas.png")} style={{width:200, marginLeft:270, position:'absolute', opacity: '0.2', marginTop:30}}/>
              <div className="rowHorizontal" style={{position:'absolute'}}>
                <div style={{width:200, margin:5, marginLeft:50}}>
                  <label>No. Pendaftaran</label>
                </div>
                <div style={{margin:5}}>
                  <label>: {this.state.year + '-'+ this.state.registration_type_id + '-' + this.state.registration_number}</label>
                </div>
              </div>
              
              <div className="rowHorizontal" style={{position:'absolute', marginTop:25}}>
                <div style={{width:200, margin:5, marginLeft:50}}>
                  <label>No. VA</label>
                </div>
                <div style={{margin:5}}>
                  <label>: {this.state.id}</label>
                </div>
              </div>

              <div className="rowHorizontal" style={{position:'absolute', marginTop:50}}>
                <div style={{width:200, margin:5, marginLeft:50}}>
                  <label>Nama Calon Siswa</label>
                </div>
                <div style={{margin:5}}>
                  <label>: {this.state.fullname}</label>
                </div>
              </div>

              <div className="rowHorizontal" style={{position:'absolute', marginTop:75}}>
                <div style={{width:200, margin:5, marginLeft:50}}>
                  <label>Asal Sekolah</label>
                </div>
                <div style={{margin:5}}>
                  <label>: {this.state.school_from_name}</label>
                </div>
              </div>

              <div className="rowHorizontal" style={{position:'absolute', marginTop:100}}>
                <div style={{width:200, margin:5, marginLeft:50}}>
                  <label>Harga Formulir</label>
                </div>
                <div style={{margin:5}}>
                  <label>: {'Rp. ' + currency.format(this.state.registration_type.registration_fee)}</label>
                </div>
              </div>
            </div>

            <div className="rowHorizontal"  style={{alignContent:'right', alignItems: 'right', justifyContent: 'right', marginRight:20, marginTop:150 }}>
              <div>
                <label>{this.state.kota}, {moment().format('DD-MM-YYYY')}</label>
              </div>
            </div>


           <div className="rowHorizontal"  style={{alignContent:'right', alignItems: 'right', justifyContent: 'right', marginRight:20 }}>
                <div>
                <label>PANITIA PPDB</label>
              </div>
            </div>

            <div className="rowHorizontal"  style={{alignContent:'right', alignItems: 'right', justifyContent: 'right', marginRight:20 }}>
                <div>
                <label>{this.state.nama_sekolah}</label>
              </div>
            </div>

        </div>
        : ''
    );
  }
}

class Cetak extends Component {

  constructor(props){
    super(props);

    this.state={
        student_id : '',
    }
  }

  
  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">

                        <div className="divContainter">
                          <Breadcrumb>
                            <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Cetak Bukti Pendaftaran</Breadcrumb.Item>
                          </Breadcrumb>

                          <div>
                            <ReactToPrint
                              trigger={() =>  <Button style={{marginBottom:10}} block>Print</Button>}
                              content={() => this.componentRef}
                            />
                            <ComponentToPrint ref={el => (this.componentRef = el)} />
                          </div>

                          
                        </div>
                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}

export default Cetak;